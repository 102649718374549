import React from "react";
import styled from "styled-components";
import ContactForm from "../components/ContactForm";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";

const ContactUsStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .left {
    grid-column: 2 / span 6;
    grid-row: 1 / 1;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
    }
  }

  .information {
    grid-column: 8 / span 6;
    padding-left: 2em;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
      padding-left: 0;
      margin-top: 2em;
    }
    ul {
      li {
        list-style: none;
        margin-bottom: 0.5em;
        &.company-name {
          font-weight: 600;
        }
      }
    }
  }
`;

const ContactUs = () => {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <TopTitleBar title="Contact Us" />
      <ContactUsStyles>
        <div className="left">
          <p>
            Please contact us using the form below with any questions, comments,
            concerns or to schedule a showing. We will promptly respond to your
            inquiry as we frequently check our e-mail. We rely on this contact
            tool - so feel free to start typing away and remember to check your
            inbox for our response!
          </p>
          <ContactForm />
        </div>
        <div className="information">
          <h2>Information</h2>
          <ul>
            <li className="company-name">J&amp;J Investment Properties LLC</li>
            <li>
              4 Howard Court
              <br />
              Carle Place, NY 11514
            </li>
            <li>
              <a href="tel:516-672-8754">(516) 672-8754</a>
            </li>
            <li>
              <a href="mailto:jandjip@jandjip.com">jandjip@jandjip.com</a>
            </li>
          </ul>
        </div>
      </ContactUsStyles>
    </Layout>
  );
};

export default ContactUs;
